<template>
    <div class="pageBox">
        <div class="mainPage">
            <div class="title">{{ data.title }}</div>
            <div class="titleTips">
                <div class="lineCss"></div>
                <div class="centerTips">
                    <div style="margin-right: 50px;">{{ data.createtime }}</div>
                    <div>{{data.sourcename}}</div>
                </div>
                <div class="lineCss"></div>
            </div>
            <div class="containBox" v-html="data.context">

            </div>
        </div>
    </div>
</template>
<script>
import { OH_preview } from '@/api/openDetil.js'
export default{
    data(){
        return{
            id:null,
            data:{},
        }
    },
    mounted(){
        this.id = this.$route.query.id
        if(this.id){
            this.getDetils()
        }
    },
    methods:{
        getDetils(){
            OH_preview(this.id).then(r=>{
                this.data = r.data
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.pageBox{
    width:100%;
    padding:0px;
    padding-top: 100px;
}

.mainPage{
    width: 1400px;
    min-height: 500px;
    border-top: 3px solid blue;
    margin:  0 auto;
    background-color: white;
    padding: 30px 20px;
    box-sizing: border-box;
    .titleTips{
        font-size: 14px;
        line-height: 50px;
        color: #666;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
        .centerTips{
            min-width: 300px;
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
        .lineCss{
            flex: 1;
            height: 1px;
            background-color: #e8e8e8;
        }
    }
    .title{
        font-size: 22px;
        line-height: 100px;
        text-align: center;
    }
    .containBox{
        margin-top: 20px;
    }
}
</style>